import React from 'react'
import './portfolio.css'
import infantus from '../../assets/infantus.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'
import IMG4 from '../../assets/portfolio4.png'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.png'
import IMG7 from '../../assets/schutz.png'
import IMG8 from '../../assets/arezzo.png'
import IMG9 from '../../assets/ab.png'
import IMG10 from '../../assets/velocita.png'
import IMG11 from '../../assets/fabrica80.png'

const data = [
  {
    id: 1,
    image: IMG7,
    title: 'Schutz USA',
    github: '#',
    demo: 'https://schutz-shoes.com/',
  },
  {
    id: 2,
    image: IMG9,
    title: 'Alexandre Birman USA',
    github: '#',
    demo: 'https://alexandrebirman.com/'
  },
  {
    id: 3,
    image: IMG8,
    title: 'Arezzo USA',
    github: '#',
    demo: 'https://www.arezzo-store.com/'
  },  
  {
    id: 4,
    image: IMG3,
    title: 'For Design',
    github: '#',
    demo: 'https://www.jaderalmeida.com/'
  },
  {
    id: 5,
    image: IMG2,
    title: 'For Accessibility',
    github: '#',
    demo: 'https://www.powerlite.com.br/'
  },
  {
    id: 6,
    image: IMG10,
    title: 'Valocita',
    github: '#',
    demo: 'https://www.velocita.com.br/',    
  },
  {
    id: 7,
    image: IMG5,
    title: 'For jewelry',
    github: '#',
    demo: 'https://stupidoficial.vnda.dev/'
  },
  {
    id: 8,
    image: IMG6,
    title: 'For Fashion',
    github: '#',
    demo: 'https://faircloset.vnda.dev/'
  },
  {
    id: 9,
    image: infantus,
    title: 'For kids',
    github: '#',
    demo: 'https://infantus.vnda.dev/',
  }, 
  {
    id: 10,
    image: IMG4,
    title: 'Fabrica80',
    github: '#',
    demo: 'https://www.fabrica80.com.br/'
  },    
  {
    id: 11,
    image: IMG11,
    title: 'For tech',
    github: '#',
    demo: 'https://vaidetech.vnda.dev/'
  }, 
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My recent work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({ id, image, title, github, demo }) => {
            return (
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio