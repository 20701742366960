import React from 'react'
import './about.css'
import ME from '../../assets/me.jpg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Frontend</h5>
              <small>3+ XP</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5>Clients</h5>
              <small>100+ Clients</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5>Ecommerce</h5>
              <small>50+ Projects</small>
            </article>
          </div>

          <div className='container__resume'>
            <p>
              ✨ Passionate about translating creative ideas into exceptional online experiences, 
              I am a Shopify Developer with expertise in HTML/CSS, JavaScript, Liquid, and SQL. 
              I am also a web performance specialist, constantly seeking ways to enhance user experiences.
            </p>

            <p>
              🚀 Throughout my career, I have contributed to the success of over 50 e-commerce and 
              ERP projects that have impacted the lives of more than 1 MILLION users worldwide. 
              I apply my skills in crafting intuitive and responsive interfaces. My focus extends beyond 
              aesthetics to efficiency, ensuring that each website achieves peak performance.
            </p>
      
            <p>
              I am an expert in turning ideas into reality!
            </p>
          </div>

          <a href="#contact" className="btn btn-primary">Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About